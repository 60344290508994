import axios from "axios";

export class LibrosAPI{

    // DEFINICIÓN DE LA ESTRUCTURA DE UN LIBRO
    /**
     * @typedef {{
    author: string
    authorSecundary: string | null,
    codigoBISAC: string
    compatibilidad: string,
    contributor: string,
    curso: string,
    cursos: string[],
    dba1: string,
    dba2: string,
    dba3: string,
    descripcionDba:string,
    description: string,
    dificultadLectora: string | null
    edad: string,
    editorial: string,
    favorito: boolean | 0 | 1,
    formato: string,
    genero: string,
    gratis: boolean | 0 | 1,
    idActividad: string | string[] | null,
    actividad:string | string[] | null,
    actividades:string | string[] | null,
    idLibro: string,
    idioma: string,
    idioma2: string | null,
    interactivo: boolean | 0 | 1,
    isbn: string,
    levellingENG: string | null,
    linkBlog: string,
    nivelUsuarioLector: string,
    portada:string,
    recomendado1: {
        gratis: boolean | 0 | 1,
        idLibro: string,
        interactivo: boolean | 0 | 1,
        portada: string,
        sonido: boolean | 0 | 1,
        title: string
    },
    recomendado2: {
        gratis: boolean | 0 | 1,
        idLibro: string,
        interactivo: boolean | 0 | 1,
        portada: string,
        sonido: boolean | 0 | 1,
        title: string
    },
    recomendado3: {
        gratis: boolean | 0 | 1,
        idLibro: string,
        interactivo: boolean | 0 | 1,
        portada: string,
        sonido: boolean | 0 | 1,
        title: string
    },
    gratis: boolean | 0 | 1,
    idLibro: string,
    interactivo: boolean | 0 | 1,
    portada: string,
    sonido: boolean | 0 | 1,
    title: string | null,
    titulo: string | null,
    screenshot1: string,    
    screenshot2: string,  
    screenshot3: string,  
    sonido: boolean | 0 | 1,
    subject: string,
    tag1: string,
    tag2: string,
    tag3: string,
    tag4: string,
    tag5: string,
    tiempoLectura: string,
    title: string,
    valores: string,
}} Libro

  // DEFINICIÓN DE LA ESTRUCTURA DE FILTROS
    /**
     * @typedef {
       {
            idLibro: string,
            idSede: string,
            sch: string,
            materias: string,
            edad: string,,
            genero: string,
            nivelUsuario: string,
            paquete: string,
            actividad:
        }
    } DatosLibrosFiltros
     */
//---------------------------------------------------------------------------------
    // EMPIEZAN LOS SERVICIOS
       /**
     * Devuelve todos los libros asignados a un instituto  
     * @returns {Promise<Libro[]>} Libro[]
     */
        static async obtenerTodos(data){
            const {abortSignal} = data || {};
          try {
              axios.defaults.baseURL = process.env.REACT_APP_API_URL;
              axios.defaults.withCredentials = true;
              const response = await axios({
                  method:'POST',
                  url:'libros/datosLibros',
                  data:{},
                  signal:abortSignal
               }) 
               const libros = response.data?.data;
               return libros?libros:[]
             
          } catch (error) {
              return Promise.reject(error);
          }
        
      }
    /**
     * Devuelve los libros asignados a un instituto o sede filtrados por una materia especifica
     * @param {string} materia - nombre de la materia o materias separadas por ;
     * @returns {Promise<Libro[]>} Libro[]
     */
    static async filtrarPorMateria(materia){
        try {
            axios.defaults.baseURL = process.env.REACT_APP_API_URL;
            axios.defaults.withCredentials = true;
            const response = await axios({
                method:'POST',
                url:'libros/datosLibros',
                data:{materias:materia}
             }) 
             const libros = response.data?.data;
             return libros?libros:[]
        } catch (error) {
            return Promise.reject(error);
        }
      
    }
     /**
     * Devuelve los libros asignados a un instituto o sede filtrados por un genero o más
     * @param {string} genero - nombre del genero o generos separados por ;
     * @returns {Promise<Libro[]>} Libro[]
     */
      static async filtrarPorGenero(genero){
        try {
            axios.defaults.baseURL = process.env.REACT_APP_API_URL;
            axios.defaults.withCredentials = true;
            const response = await axios({
                method:'POST',
                url:'libros/datosLibros',
                data:{genero:genero}
             }) 
             const libros = response.data?.data;
               return libros?libros:[]
        } catch (error) {
            return Promise.reject(error);
        }
      
    }
         /**
     * Devuelve los libros que coincidan con el parametro de busqueda - titulo, autor o palabra clave
     * POR AHORA SOLO FUNCIONA LA BUSQUEDA POR TITULO
     * @param {string} busqueda - titulo, palabra clave, autor - LAS ULTIMAS 2 AÚN NO DISPONIBLES
     * @returns {Promise<Libro[]>} Libro[]
     */
          static async busquedaGeneral(busqueda){
            try {
                axios.defaults.baseURL = process.env.REACT_APP_API_URL;
                axios.defaults.withCredentials = true;
                const response = await axios({
                    method:'POST',
                    url:'libros/datosLibros',
                    data:{sch:busqueda},
                 }) 
                 const libros = response.data?.data;
                 return libros?libros:[]
            } catch (error) {
                return Promise.reject(error);
            }
          
        }

             /**
     * Devuelve los libros que coincidan con los filtros definidos.
     * @param {DatosLibrosFiltros} filtros - objeto solo con los filtros que se desea usar, los que no se vayan a usar  no agregarlos.
     * Si no se envia el objeto o esta vacio se devolvera todos los libros
     * @returns {Promise<Libro[]>} Libro[]
     */
              static async multiplesFiltros(filtros, abortSignal){
                try {
                    axios.defaults.baseURL = process.env.REACT_APP_API_URL;
                    axios.defaults.withCredentials = true;
                    const response = await axios({
                        method:'POST',
                        url:'libros/datosLibros',
                        data:filtros || {},
                        signal:abortSignal
                     }) 
                     const libros = response.data?.data;
                     return libros?libros:[]
                } catch (error) {
                    return Promise.reject(error);
                }
              
            }

     /**
     * Devuelve un libro especifico asignado a una sede por su ID, si no existe retorna undefined.
     * @param {string} idLibro - id del libro
     * @returns {Promise<Libro | undefined>} Libro o undefined
     */
      static async buscarPorId({bookId, abortSignal}){
        try {
            axios.defaults.baseURL = process.env.REACT_APP_API_URL;
            axios.defaults.withCredentials = true;
            const response = await axios({
                method:'POST',
                url:'libros/datosLibros',
                data:{idLibro:bookId},
                signal:abortSignal
             })
             const libros = response.data?.data;
             return libros  && libros[0]?libros[0]:undefined

        } catch (error) {
            return Promise.reject(error);
        }
      
    }   
    
      /**
     * Devuelve el arreglo de libros para una sede en especifico.
     * @param {string} idSede - el id de la sede
     * @returns {Promise<Libro[]>} Libro[]
     */
       static async buscarPorSede(idSede){
        try {
            axios.defaults.baseURL = process.env.REACT_APP_API_URL;
            axios.defaults.withCredentials = true;
            const response = await axios({
                method:'POST',
                url:'libros/datosLibros',
                data:{idSede:idSede}
             })
             const libros = response.data?.data;
             return libros?libros:[];
        } catch (error) {
            return Promise.reject(error);
        }
      
    } 
    
       /**
     * Devuelve todos los libros existentes y disponibles pero especifica si estan elegidos o no para un paquete en especifico
     * @param {string} idPaquete 
     * @param {DatosLibrosFiltros} filtros 
     * @returns {Promise<Libro[]>} la información de libro más la propiedad de 'elegido'
     */
        static async librosPorPaquete(idPaquete, filtros){
            const body = {...filtros, paquetes:idPaquete || '.'};
            try {
                axios.defaults.baseURL = process.env.REACT_APP_API_URL;
                axios.defaults.withCredentials = true;
                const URL = 'libros/librosPaquete'
                const response = await axios({
                    method:'POST',
                    url:URL,
                    data:body
                 }) 
                 const data = response.data?.data;
                return data?data:[]
            } catch (error) {
                return Promise.reject(error);
            }
        }


    
        /**
         * Devuelve un libro en especifico buscando en todos los libros existentes y disponibles.
         * @param {string} idLibro 
         * @returns {Promise<Libro | undefined>} devuelve el libro o indefinido si no se encontro
         */
        static async todosBuscarPorId(idLibro) {
            try {
                axios.defaults.baseURL = process.env.REACT_APP_API_URL;
                axios.defaults.withCredentials = true;
                const URL = 'libros/librosPaquete'
                const response = await axios({
                    method: 'POST',
                    url: URL,
                    data: { paquetes: "." }
                })
                const data = response.data?.data;
                if (Array.isArray(data) && data.length > 0) {
                    const libro = data.find(libro => libro.idLibro === idLibro);
                    return libro ? libro : undefined;
                }
                return undefined;
            } catch (error) {
                return Promise.reject(error);
            }
        }

      /**
     * Devuelve el arreglo de libros que son favoritos.
     * @param {string} idSede - el id de la sede
     * @returns {Promise<Libro[]>} Libro[]
     */
       static async obtenerFavoritos(data){
        const {abortSignal} = data || {};
        try {
            axios.defaults.baseURL = process.env.REACT_APP_API_URL;
            axios.defaults.withCredentials = true;
            const response = await axios({
                method:'POST',
                url:'libros/datosLibros',
                data:{favorito:1},
                signal:abortSignal
             })
             const libros = response.data?.data;
             return libros?libros:[];
        } catch (error) {
            return Promise.reject(error);
        }
      
    } 
       /**
     * Devuelve los libros recientes y escogidos por el profesor del estudiante.
     * El cliente debe estar con el rol de estudiante.
     * @returns {Promise<{
        escogidosPorElProfesor:any[],
        leidosRecientemente:any[],
    }>} libros del estudiante
     */
        static async obtenerLibrosEstudiante(data){
            const {abortSignal, home} = data || {};
            try {
                axios.defaults.baseURL = process.env.REACT_APP_API_URL;
                axios.defaults.withCredentials = true;
                const response = await axios({
                    method:'POST',
                    url:'perfilEstudiante/librosEstudiante',
                    signal:abortSignal,
                    data:{
                        home: home || 0
                    }
                 })
                 const libros = response.data?.data;
                 if(response?.data?.status !== 1){
                    return new Error(response?.data?.info || 'An error has ocurred, we are trying to solve it.');
                 }
                 return {
                    ...libros,
                    leidosRecientemente:libros?.leidosRecientemente || [],
                    escogidosPorElProfesor:libros?.escogidosPorElProfesor || []
                 };
            } catch (error) {
                return Promise.reject(error);
            }
          
        } 


    // SERVICIOS CON ABORT SIGNAL------------------------------------------------------------------------


          /**
     * Devuelve el arreglo de libros para una sede en especifico.
     * @param {string} idSede - el id de la sede
     * @returns {Promise<Libro[]>} Libro[]
     */
          static async getBooksByBuilding({building, abortSignal}) {
            try {
                axios.defaults.baseURL = process.env.REACT_APP_API_URL;
                axios.defaults.withCredentials = true;
                const response = await axios({
                    method:'POST',
                    url:'libros/datosLibros',
                    data:{idSede:building},
                    signal:abortSignal
                 })
                 const libros = response.data?.data;
                 return libros?libros:[];
            } catch (error) {
                return Promise.reject(error);
            }
          
        } 

}
