import { useState } from "react";
import DivisionCrud from "../../globales/DivisionCrud";
import Selector from "../../globales/elementosFormularios/Selector";

const CrearEstudianteIndividual = (props) => {
  const { actualizarCodigo, actualizarCorreo, actualizarNombre, cambiarCurso, cambiarSede, codigoEstudiante, mostrarAlerta, textosInterfaz, sedesInstituto, sedeSeleccionada, cursosSede, cursoSeleccionado, inputNombre, setInputNombre, inputCorreo, setInputCorreo } = props;

  const { codigo, correo, curso, nombre, sede } = textosInterfaz;

  const [inputCodigo, setInputCodigo] = useState({ campo: codigoEstudiante, valido: true });

  /*expresion */
  const expresionCorreo = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;

  /**funciones */
  const validarCampoVacio = (estado, actualizarEstado) => {
    const valorCampo = estado.campo.length > 0 ? true : false;
    actualizarEstado({ ...estado, valido: valorCampo });
  }

  /*estaa funciones actualizan el valor del input y lo agrega en el estado */
  const actualizarCampoCodigo = (e, estado, cambiarEstado) => {
    const valorCampo = e.target.value;
    actualizarCodigo({ codigo: valorCampo });
    cambiarEstado({ ...estado, campo: valorCampo });
  }

  const actualizarCampoNombre = (e, estado, cambiarEstado) => {
    const valorCampo = e.target.value;
    actualizarNombre({ nombre: valorCampo });
    cambiarEstado({ ...estado, campo: valorCampo });
  }

  const actualizarCampoCorreo = (e, estado, cambiarEstado) => {
    const valorCampo = e.target.value;
    actualizarCorreo({ correo: valorCampo });
    cambiarEstado({ ...estado, campo: valorCampo });
  }

  /**Esta función comprueba que el valor del campo correo cumple con la expresión y cambia su estado */
  const validarCorreo = (expresion, estado, cambiarEstado) => {
    const valor = estado.campo !== '' ? expresion.test(estado.campo) : false;
    cambiarEstado({ ...estado, valido: valor });
  }


  return (
    <>
      {/* codigo*/}
      <DivisionCrud>
        <>
          <h3 className="raleway-700 onbackground-var1--color">{codigo.titulo}</h3>
          <p className="r4-14-gris6a">{codigo.descripcion}</p>
        </>
        <div className="xl:w-451p">
          <input
            className={`w-full`}
            onBlur={() => { validarCampoVacio(inputCodigo, setInputCodigo) }}
            onChange={(e) => {
              actualizarCampoCodigo(e, inputCodigo, setInputCodigo)
            }}
            onKeyUp={() => { validarCampoVacio(inputCodigo, setInputCodigo) }}
            value={inputCodigo.campo}
            placeholder={codigo.placeholder}
            type="text"
          />
        </div>
      </DivisionCrud>

      {/* nombre*/}
      <DivisionCrud>
        <>
          <h3 className="raleway-700 onbackground-var1--color">{nombre.titulo} <span className="asterisco">*</span></h3>
          <p className="r4-14-gris6a">{nombre.descripcion}</p>
        </>
        <div className="xl:w-451p">
          <input
            className={`w-full ${mostrarAlerta ? !inputNombre.valido && 'input_error' : null}`}
            onBlur={() => { validarCampoVacio(inputNombre, setInputNombre) }}
            onChange={(e) => {
              actualizarCampoNombre(e, inputNombre, setInputNombre)
            }}
            onKeyUp={() => { validarCampoVacio(inputNombre, setInputNombre) }}
            value={inputNombre.campo}
            placeholder={nombre.placeholder}
            type="text"
          />
          {mostrarAlerta &&
            <p role="alert" aria-live="assertive" className={`text-14 
                  ${!inputNombre.valido ? 'animate-fadeIn alert_error' : 'hidden  animate-fadeOut'}`
            }>
              {nombre.error}
            </p>
          }
        </div>
      </DivisionCrud>

      {/* Correo */}
      <DivisionCrud>
        <>
          <h3 className="raleway-700 onbackground-var1--color">{correo.titulo} <span className="asterisco"></span> </h3>
          <p className="r4-14-gris6a">{correo.descripcion}</p>
        </>
        <div className="alineado-verticalmente h-full">
          <div className="w-full xl:w-451p">
            <input
              type="email"
              className={`w-full ${mostrarAlerta ? !inputCorreo.valido && 'input_error' : null}`}
              onChange={(e) => {
                actualizarCampoCorreo(e, inputCorreo, setInputCorreo);
              }}
              // onKeyUp={() => validarCorreo(expresionCorreo, inputCorreo, setInputCorreo)}
              // onBlur={() => validarCorreo(expresionCorreo, inputCorreo, setInputCorreo)}
              placeholder={correo.placeholder}
              value={inputCorreo.campo}
            />

            {mostrarAlerta &&
              <p role="alert" aria-live="assertive" className={`text-14 
                  ${!inputCorreo.valido ? 'animate-fadeIn alert_error' : 'hidden  animate-fadeOut'}`
              }>
                {correo.error}
              </p>
            }
            {/*  <p className={`text-14 ${mostrarAlerta && inputCorreo.valido ? 'hidden' : 'alert_error'}`}>{correo.error}</p> */}
          </div>
        </div>
      </DivisionCrud>

      {/* sede */}
      <DivisionCrud>
        <>
          <h3 className="raleway-700 onbackground-var1--color">{sede.titulo} <span className="asterisco">*</span></h3>
          <p className="r4-14-gris6a">{sede.descripcion}</p>
        </>
        <div className="xl:w-451p">
          <Selector
            cambiarElegido={cambiarSede}
            desactivado={false}
            estadoEnviar={mostrarAlerta}
            estiloTipoInput={true}
            opciones={sedesInstituto}
            superposicion={true}
            textoDefault={sede.placeholder}
            textoElegido={sedeSeleccionada.nombre}
            textoError={sede.error}
            valido={!!sedeSeleccionada.id}
          />
        </div>
      </DivisionCrud>

      {/* curso */}
      <DivisionCrud>
        <>
          <h3 className="raleway-700 onbackground-var1--color">{curso.titulo} <span className="asterisco">*</span></h3>
          <p className="r4-14-gris6a">{curso.descripcion}</p>
        </>
        <div className="xl:w-451p">
          <Selector
            cambiarElegido={cambiarCurso}
            desactivado={false}
            estadoEnviar={mostrarAlerta}
            estiloTipoInput={true}
            opciones={cursosSede.resultados}
            textoDefault={curso.placeholder}
            textoElegido={cursoSeleccionado.nombre}
            textoError={curso.error}
            textoNoHayOpciones={curso.no_hay}
            valido={!!cursoSeleccionado.id}
          />
        </div>
      </DivisionCrud>
    </>
  )
}
export default CrearEstudianteIndividual;